import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout/Layout';
import PostListWrapper from '../components/Container/Stream/PostList/PostListWrapper';
import DossierListWrapper from '../components/Container/Stream/DossierList/DossierListWrapper';
import Section from '../components/Layout/Section/Section';
import NLF from '../components/Container/NLF/NLF';
import MSSlider from '../components/Content/MSSlider/MSSlider';
import Meta from '../components/Layout/Meta';
import CleoApp from '../components/Container/CleoApp/CleoApp';
import ServiceTeaserHome from '../components/Content/ServiceTeaser/ServiceTeaserHome';
import Headline from '../components/Elements/Headline/Headline';
import Text from '../components/Elements/Text/Text';
import Button from '../components/Elements/Button/Button';
import LogoCommunity from '../components/Layout/Logo/LogoCommunity';
import Row from '../components/Layout/Grid/Row';
import Column from '../components/Layout/Grid/Column';
import ContactSection from '../components/Content/ContactSection/ContactSection';
import Intro from '../components/Elements/Intro/Intro';
import Divider from '../components/Elements/Divider/Divider';

class Home extends Component {
	render() {
		const {data, location} = this.props;
		const {edges: posts} = data.allWordpressPost;
		const {edges: news} = data.allWordpressWpNews;
		return (
			<>
				<Meta />
				<Layout location={location}>
					<Section>
						<NLF />
					</Section>
					<Section center showPadding theme="grey">
						<Row styles={{marginBottom: false}}>
							<Column>
								<Headline text="News und Specials" as="h2" />
								<Intro
									maxWidth={1000}
									content="Hier findest du die aktuellsten Informationen und Spezialthemen rund um deine MS!"
								/>
							</Column>
						</Row>
						<DossierListWrapper posts={news} section="shortDossiersList" />
						<Row>
							<Column>
								<Text
									maxWidth={1000}
									center
									content="<p>Du möchtest noch mehr aktuelle Inhalte und Spezialthemen sehen?<br/>Dann schau in unseren neuen Bereich!</p>"
								/>
								<Button
									type="link"
									text="Mehr anzeigen"
									to="/multiple-sklerose/news-specials"
									theme="candy"
								/>
							</Column>
						</Row>
					</Section>
					<Section center showPadding theme="white">
						<Row styles={{marginBottom: true}}>
							<Column>
								<Headline
									text="360°-Services: <br/>Damit alles gut läuft – gleich von Anfang an"
									as="h2"
								/>
								<Intro
									maxWidth={1000}
									content="Für MS-Betroffene und deren Angehörige – individuell, digital und kostenlos."
								/>
								<Text
									maxWidth={1000}
									center
									content="Die 360°-Services sind informativ, digital und auf Wunsch ganz persönlich. Wir nehmen uns Zeit für dich, dein Leben und deine Behandlung – und unterstützen dich so, wie du es dir vorstellst. Auch in mehreren Fremdsprachen. Für ein aktives und selbstbestimmtes Leben. Probier es aus."
								/>
							</Column>
						</Row>
						<Row>
							<Column>
								<ServiceTeaserHome />
							</Column>
						</Row>
					</Section>
					<Section showPadding theme="grey" paddingBottom>
						<PostListWrapper posts={posts} title="Leben mit MS" section="shortTeaserList" />
					</Section>
					<Divider fancy />
					<Section showPadding theme="white" paddingTop>
						<MSSlider type="homepage" />
					</Section>
					<Section>
						<CleoApp />
					</Section>
					<Section center showPadding theme="grey">
						<Row styles={{marginBottom: true}}>
							<Column>
								<LogoCommunity />
							</Column>
						</Row>
						<Row styles={{marginBottom: true}}>
							<Column>
								<Text
									content="In der msCommunity kannst du anderen Betroffenen Fragen stellen und mit eigenen Erfahrungen helfen."
									maxWidth={850}
								/>
							</Column>
						</Row>
						<Row>
							<Column>
								<Button type="link" text="Zum Diskussionsforum" to="/ms-community/" theme="white" />
								<Button type="link" text="Jetzt anmelden" to="/registrieren" theme="candy" />
							</Column>
						</Row>
					</Section>
					<Section>
						<Row>
							<Text
								content="Biogen-165196"
								styles={{color: 'rgb(170, 170, 170)', fontSize: '0.7em', margin: '2rem'}}
							/>
						</Row>
					</Section>

					<Section>
						<ContactSection />
					</Section>
				</Layout>
			</>
		);
	}
}

export default Home;

Home.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

export const pageQuery = graphql`
	query {
		allWordpressPage {
			edges {
				node {
					id
					title
					excerpt
					slug
					date(formatString: "MMMM DD, YYYY")
				}
			}
		}
		allWordpressPost(sort: {fields: [date], order: DESC}) {
			edges {
				node {
					id
					title
					excerpt
					wordpress_id
					primary_category {
						name
					}
					acf {
						hidden
						persona
					}
					categories {
						id
						name
					}
					date(formatString: "MMMM DD, YYYY")
					slug
					path
					featured_media {
						localFile {
							childImageSharp {
								id
								fluid(srcSetBreakpoints: [800, 250], quality: 80) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
		allWordpressWpNews(sort: {fields: [date], order: DESC}, filter: {acf: {hide_in_flow: {eq: null}}}) {
			edges {
				node {
					id
					title
					excerpt
					wordpress_id
					acf {
						hidden
						hide_in_flow
					}
					tags {
						name
					}
					date(formatString: "MMMM DD, YYYY")
					slug
					featured_media {
						localFile {
							childImageSharp {
								id
								fluid(srcSetBreakpoints: [800, 250], quality: 80) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
	}
`;
