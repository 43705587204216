import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import PostList from './Container';

const PostListWrapper = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressPost(sort: {fields: [date], order: DESC}) {
					edges {
						node {
							id
							title
							excerpt
							wordpress_id
							primary_category {
								name
							}
							acf {
								hidden
								persona
							}
							categories {
								id
								name
							}
							date(formatString: "MMMM DD, YYYY")
							slug
							path
							featured_media {
								localFile {
									childImageSharp {
										id
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => <PostList data={data} {...props} />}
	/>
);

export default PostListWrapper;
