import styled, {css, keyframes} from 'styled-components';
// import Select from 'react-dropdown-select';
import Dropdown from 'react-dropdown';

import Text from 'components/Elements/Text/Text';

import {withUnit, media, gradientCandyCarrot} from '../../../styles/utils';
import {sizes, colors, fonts} from '../../../styles/variables';
import {HeadlineStyledH1} from '../../Elements/Headline/styles';
import Image from '../../Elements/Image/Image';
import Button from '../../Elements/Button/Button';
import {Icon} from '../../Elements/Icon/Icon';
import IcoPlus from '../../../assets/img/icons/ico-plus-white.svg';

import headerDesktop from '../../../assets/img/psp/header-desktop.jpg';
import headerMobile from '../../../assets/img/psp/header-mobile.jpg';

export const Step = styled.div`
	display: block;
	margin: 1rem 0;
	${media.lg`
		margin: 0.25rem 0;
	`}
`;

export const SelectContainerStyled = styled.div`
	display: block;

	${media.sm`
		display: inline-block;
	`};
`;

export const SelectStyled = styled(Dropdown)`
	position: relative;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.3);
	border: none;
	border-radius: 0.5rem !important;
	padding: 0;

	line-height: 1.2;
	cursor: pointer;
	.Dropdown-root {
		padding: 0;
		font-family: ${fonts.serif};
	}

	.Dropdown-placeholder {
		font-family: ${fonts.serif};
		opacity: 0.8;
	}

	.Dropdown-control {
		padding: 0.5rem 3.5rem 0.5rem 1rem !important;
	}

	&.is-open .Dropdown-menu {
		position: absolute;
	}

	.is-selected {
		font-family: ${fonts.serif};
	}

	svg {
		height: 1em !important;
		width: 1em !important;
	}

	.Dropdown-menu {
		background: white;
		border-radius: 0;
		width: max-content;
		color: black;
		font-family: ${fonts.serif};
		padding: ${withUnit(sizes.distance.base / 2)};
		z-index: 999;
		opacity: 1;
	}

	.Dropdown-option {
		font-family: ${fonts.serif};
		font-size: 1em;
		padding: 0.3rem 0;
		${media.lg`
			font-size: 0.9em;
			padding: 0.2rem 0;
		`}
	}
`;
export const SectionContainer = styled.div`
	width: 90%;
	height: 60%;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
`;

export const BackgroundImage = styled.div`
	overflow: hidden;
	position: absolute;
	max-height: 800px;
	max-width: 800px;
	height: 100%;
	width: 100%;
	right: 0;
	bottom: 0;
`;

export const NLFStyled = styled.div`
	box-sizing: border-box;
	position: relative;
	z-index: 1;
	height: auto;
	min-height: 70vh;
	display: flex;
	align-items: center;
	margin-top: ${withUnit(sizes.navbar.sm.default)};

	&::before {
		content: '';
		min-height: inherit;
	}

	${media.lg`
		margin-top: ${withUnit(sizes.navbar.default)};
	`};

	&.ap-nlf-gs {
		height: 100vh;

		${media.md`
			height: auto;
			min-height: 70vh;
		`};
	}
`;

export const BGImageStyled = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	z-index: -1;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-image: url(${headerMobile});
		background-size: cover;
		background-position: top right;

		${(props) => (props.withMobileFallback ? `${gradientCandyCarrot()};` : ``)};

		${media.lg`
			background-image: url(${headerDesktop});
			background-size: cover;
			background-position: top right; 
		`};
	}
`;

export const SectionStyled = styled.div`
	box-sizing: border-box;
	z-index: 10;
	padding: 2em;
	display: flex;
	justify-content: center;
	flex-direction: column;

	${media.xxl`
		padding-left: 10em;
	`};

	${media.xxxl`
		padding-left: 16em;
	`};
`;

export const StyledImage = styled(Image)`
	width: 5rem !important;
	height: 5rem;
`;

export const StyledText = styled(Text)`
	margin: 0 0 0.5rem 2rem;
	color: ${colors.white};
	font-weight: 300;
	font-size: 1.5rem;
	margin-bottom: ${(props) => props.marginBottom};

	&::before {
		content: '';
		background: url(${IcoPlus}) no-repeat;
		background-size: contain;
		position: absolute;
		width: 1rem;
		height: 1rem;
		margin-left: -2rem;
		margin-top: 0.6rem;
	}
`;

export const NLFContentStyled = styled.div`
	box-sizing: border-box;
	//todo: figure out why it must be positioned absolute to top 0
	//position: absolute;
	//top: 0;
	//left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
	padding: ${withUnit(sizes.distance.base / 2)};

	${media.sm`
		padding: ${withUnit(sizes.distance.base)};
		height: 60%;
	`};

	${media.lg`
		padding: ${withUnit(sizes.distance.base * 2)};
		height: 100%;
	`};
`;

export const HeadlineStyled = styled(HeadlineStyledH1)`
	color: ${colors.white};
	font-family: ${fonts.sans_serif};
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	${media.sm`
		font-size: 2em;
	`};

	${media.lg`
		font-size: 3.2em;
	`};
`;

export const SubheadlineStyled = styled(HeadlineStyledH1)`
	color: ${colors.white};
	font-weight: 300;
	font-style: normal;
	margin-top: 0;

	${media.lg`
		font-size: 2.4em;
	`};
`;

export const ArrowClosed = styled(Icon).attrs({name: 'chevron_right'})`
	color: ${colors.white};
	font-size: 0.7em;
	margin-left: -1.7em;
	margin-right: ${withUnit(sizes.distance.base * 0.75)};
	transform: rotate(90deg);
	display: inline-block;
	position: absolute;
	top: ${withUnit(sizes.distance.base * 0.75)};
	right: 0;

	${media.sm`
		top: ${withUnit(sizes.distance.base * 0.75)};
	`};

	${media.lg`
		top: ${withUnit(sizes.distance.base)};
	`};
`;

export const ArrowOpen = styled(Icon).attrs({name: 'chevron_right'})`
	color: ${colors.white};
	position: absolute;
	font-size: 0.7em;
	margin-left: -1.7em;
	margin-right: ${withUnit(sizes.distance.base * 0.75)};
	display: inline-block;
	transform: rotate(-90deg);
	top: ${withUnit(sizes.distance.base * 0.75)};
	right: 0;

	${media.sm`
		top: ${withUnit(sizes.distance.base * 0.75)};
	`};

	${media.lg`
		top: ${withUnit(sizes.distance.base)};
	`};
`;

const SlideIn = keyframes`
	from {
		opacity: 0;
		transform: translate(0, 100%);
	}

	to {
		opacity: 1;
		transform: translate(0, 0);
	}
`;

const animationSlideIn = css`
	animation: ${SlideIn} 1.5s;
`;

export const NLFTextStyled = styled(HeadlineStyledH1)`
	font-family: ${fonts.sans_serif};
	color: ${colors.white};
	font-weight: 300;
	font-style: normal;
	line-height: 2;
	font-size: 1.2em;

	${media.sm`
		font-size: 1.4em;
	`};

	${media.lg`
		font-size: 2em;
	`};

	${media.xl`
		font-size: 2.4em;
	`};

	.slidein {
		${animationSlideIn}
	}
`;

export const ButtonContainer = styled.div`
	.slidein {
		${animationSlideIn}
	}
`;

export const ButtonStyled = styled(Button)`
	margin: 0;

	&.slidein {
		${animationSlideIn}
	}
`;

export const IconStyled = styled(Icon)`
	display: block;
	opacity: 0.3;

	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	svg {
		fill: ${colors.snow};
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		${media.lg`
			fill: ${colors.lightgrey};
		`};
	}
`;
