import styled from 'styled-components';
import {withUnit, media} from '../../../styles/utils';
import {sizes, colors, fonts} from '../../../styles/variables';
import {HeadlineStyledH1} from '../../Elements/Headline/styles';
import Button from '../../Elements/Button/Button';
import Label from '../../Elements/Label/Label';
import LogoCleo from '../../Layout/Logo/LogoCleo';
import Text from '../../Elements/Text/Text';

export const CleoAppStyled = styled.div`
	box-sizing: border-box;
	position: relative;
	z-index: 1;
`;

export const CleoLogoStyled = styled(LogoCleo)`
	display: block;
	color: ${colors.white};
	align-items: center;
	margin-bottom: ${withUnit(sizes.distance.base)};

	${media.md`
		display: flex;
	`};

	&::before {
		text-align: center;
		content: 'Hallo, ich bin ';
		display: block;
		font-family: ${fonts.serif};
		font-weight: 300;
		font-size: 2em;
		color: ${colors.white};

		${media.md`
			margin-right: 1em;
		`};
	}

	&:hover::before,
	&:active::before,
	&:focus::before {
		color: ${colors.white};
	}
`;

export const ImageStyled = styled.div`
	display: block;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	z-index: 0;
	overflow: hidden;

	&::after {
		display: none;
	}

	${media.xl`
		height: 70vh;
	`};
`;

export const TextStyled = styled(Text)`
	color: ${colors.white};
`;

export const CleoAppContentStyled = styled.div`
	box-sizing: border-box;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	padding: ${withUnit(sizes.distance.base)};
	color: ${colors.white};

	${media.lg`
		padding: ${withUnit(sizes.distance.base * 2)};
	`};
`;

export const HeadlineStyled = styled(HeadlineStyledH1)`
	color: ${colors.white};

	${media.lg`
		font-size: 2.8em;
	`};
`;

export const LabelStyled = styled(Label)`
	color: ${colors.white};
`;

export const ButtonStyled = styled(Button)`
	margin: 0;
`;
