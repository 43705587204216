import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ServiceTeaser from '../../Elements/Teaser/ServiceTeaser360/ServiceTeaser';
import {TeaserWrapper, TeaserCard} from './styles';

class ServiceTeaserHomeContent extends Component {
	render() {
		const {data} = this.props;
		return (
			<TeaserWrapper center>
				{data.map(({node: item}, index) => (
					<TeaserCard key={item.path}>
						<ServiceTeaser
							item={{
								appearance: 'shadow',
								label: item && item.acf && item.acf.topline ? item.acf.topline : null,
								title: item && item.acf && item.acf.headline ? item.acf.headline : item.title,
								subtitle: item?.acf?.subheadline,
								image: {
									localFile: {
										publicURL:
											item.featured_media && item.featured_media.localFile
												? item.featured_media.localFile.childImageSharp.fluid
												: undefined,
									},
								},
								link: {
									url: `/${item.path}`,
								},
							}}
						/>
					</TeaserCard>
				))}
			</TeaserWrapper>
		);
	}
}

export default ServiceTeaserHomeContent;

ServiceTeaserHomeContent.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
};
