import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import CleoAppContent from './CleoAppContent';

const CleoApp = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				imageOne: file(relativePath: {eq: "img_cleo.jpg"}) {
					childImageSharp {
						fluid(maxWidth: 2000, quality: 100) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		`}
		render={data => <CleoAppContent data={data} {...props} />}
	/>
);

export default CleoApp;
