import React from 'react';
import {StaticQuery, graphql} from 'gatsby';

import ServiceTeaserHomeContent from './ServiceTeaserHomeContent';

const ServiceTeaserHome = () => (
	<StaticQuery
		query={graphql`
			query {
				serviceteaser: allWordpressPage(
					filter: {parent_element: {slug: {eq: "service"}}}
					sort: {fields: menu_order}
				) {
					edges {
						node {
							title
							path
							wordpress_id
							acf {
								topline
								headline
								subheadline
							}
							featured_media {
								localFile {
									childImageSharp {
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
				community: allWordpressPage(filter: {slug: {eq: "community"}}, sort: {fields: menu_order}) {
					edges {
						node {
							title
							path
							wordpress_id
							acf {
								topline
								headline
								subheadline
							}
							featured_media {
								localFile {
									childImageSharp {
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
				ms: allWordpressPage(filter: {slug: {eq: "leben-mit-ms"}}, sort: {fields: menu_order}) {
					edges {
						node {
							title
							path
							acf {
								topline
								headline
								subheadline
							}
							featured_media {
								localFile {
									childImageSharp {
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => <ServiceTeaserHomeContent data={[...data.serviceteaser.edges]} />}
	/>
);

export default ServiceTeaserHome;
