import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
	NLFStyled,
	NLFContentStyled,
	HeadlineStyled,
	SubheadlineStyled,
	ButtonStyled,
	NLFTextStyled,
	Step,
	BGImageStyled,
	SelectStyled,
	SelectContainerStyled,
	SectionStyled,
	SectionContainer,
	ButtonContainer,
	ArrowOpen,
	ArrowClosed,
	StyledImage,
	StyledText,
} from './styles';
import {nlfOptions} from '../../../utils/nlfUtils';

import weeklyplan from '../../../assets/img/psp/weekly_plan.svg';

const NLFContent = (props) => {
	const {userManagement, data, actions} = props;
	let sel1 = null;
	let sel2 = null;
	let showSelection2 = false;
	let showSubmit = false;
	let showWhenUserNlf = false;

	if (userManagement.nlfObject && userManagement.nlfObject.nlfVal1) {
		sel1 = userManagement.nlfObject.nlfVal1;
		showSelection2 = true;
	}
	if (userManagement.nlfObject && userManagement.nlfObject.nlfVal2) {
		sel2 = userManagement.nlfObject.nlfVal2;
		showSubmit = true;
	}

	if (userManagement && userManagement.user) {
		if (userManagement.user.nlf) {
			const userNlf = parseInt(userManagement.user.nlf, 10);
			if (userNlf > 0) {
				showWhenUserNlf = true;
			}
		}
	}

	const setNlfVal1 = (event) => {
		actions.setNLFVal1(event);
	};

	const setNlfVal2 = (event) => {
		actions.setNLFVal2(event);
	};

	const options1 = [
		{value: 1, label: 'selbst betroffen'},
		{value: 2, label: 'Angehöriger eines Betroffenen'},
	];
	const options2 = [
		{value: 4, label: 'neu'},
		{value: 8, label: 'schon länger ein Thema'},
	];

	function submitFinalNLF(event) {
		const {nlfVal1, nlfVal2} = userManagement.nlfObject;
		window.dataLayer.push({
			event: 'biib_bot',
			biib_botAnswer1: nlfOptions[nlfVal1.value],
			biib_botAnswer2: nlfOptions[nlfVal2.value],
		});

		event.preventDefault();
		const {user} = userManagement;
		const finalValue = nlfVal1.value + nlfVal2.value + 10;
		actions.setNLF(finalValue);
		if (user && user.UserId) {
			fetch(`${process.env.GATSBY_UMS_URL}/userdata`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					user_id: user.UserId,
					nlf: finalValue,
				}),
			});
		}
	}

	const arrowClosed = <ArrowClosed />;
	const arrowOpen = <ArrowOpen />;
	const pspBanner = (
		<NLFStyled className="ap-nlf">
			<BGImageStyled />
			<SectionStyled>
				<SectionContainer>
					<StyledImage src={weeklyplan} />
					<HeadlineStyled>
						Digitaler <strong>Ernährungs</strong>Manager
					</HeadlineStyled>
					<StyledText content="wöchentlicher Ernährungsplaner" />
					<StyledText content="integrierte Einkaufsliste" />
					<StyledText content="interaktive Lagerungschallenge" marginBottom="2rem" />
					<ButtonContainer>
						<ButtonStyled className="slidein" text="Jetzt entdecken" theme="white" type="link" to="/nutritionmanager/" />
					</ButtonContainer>
				</SectionContainer>
			</SectionStyled>
		</NLFStyled>
	);

	if (showWhenUserNlf === true) {
		return pspBanner;
	}

	return pspBanner;

	// temporarily deactivated
	/* return (
		<NLFStyled className="ap-nlf ap-nlf-gs">
			<BGImageStyled src={data.imageOne.childImageSharp.original.src} />
			<NLFContentStyled>
				<div>
					<HeadlineStyled>Multiple Sklerose:</HeadlineStyled>
					<NLFTextStyled>
						<Step className="slidein">
							Ich bin{' '}
							<SelectContainerStyled>
								<SelectStyled
									color="white"
									placeholder="Bitte auswählen"
									options={options1}
									onChange={setNlfVal1}
									value={sel1}
									arrowClosed={arrowClosed}
									arrowOpen={arrowOpen}
								/>
							</SelectContainerStyled>
						</Step>
						{showSelection2 && (
							<Step className="slidein">
								und die MS ist für mich{' '}
								<SelectContainerStyled>
									<SelectStyled
										placeholder="Bitte auswählen"
										options={options2}
										onChange={setNlfVal2}
										value={sel2}
										arrowClosed={arrowClosed}
										arrowOpen={arrowOpen}
									/>
								</SelectContainerStyled>
							</Step>
						)}
					</NLFTextStyled>
					{showSubmit && (
						<ButtonStyled
							onClick={submitFinalNLF}
							className="slidein"
							text="Zum Profil speichern"
							theme="snow"
							type="link"
							to={userManagement.user ? '/dashboard' : '/login'}
						/>
					)}
				</div>
			</NLFContentStyled>
		</NLFStyled>
	); */
};

NLFContent.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
		nlf: PropTypes.string,
	}),
	nlf: PropTypes.number,
	handleNLF: PropTypes.func,
	data: PropTypes.objectOf(PropTypes.object),
};

NLFContent.defaultProps = {
	user: null,
	nlf: 0,
	handleNLF: null,
	data: null,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(NLFContent);
