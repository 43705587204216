import React from 'react';
import {connect} from 'react-redux';
import {StaticQuery, graphql} from 'gatsby';
import NLFContent from './NLFContent';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../state/actions/userManagement';
// import {setNLF} from '../../../state/actions/userManagement';

const NLF = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				imageOne: file(relativePath: {eq: "img_nlf-ms.jpg"}) {
					childImageSharp {
						original {
							src
						}
						fluid(maxWidth: 2000, quality: 100) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				imageTwo: file(relativePath: {eq: "img_gemeinsam-stark.png"}) {
					childImageSharp {
						original {
							src
						}
						fluid(maxWidth: 2000, quality: 100) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		`}
		render={(data) => <NLFContent data={data} {...props} />}
	/>
);

const mapStateToProps = (state) => ({
	userManagement: state.userManagement,
});

const mapDispatchToProps = (dispatch) => {
	return {actions: bindActionCreators({...Actions}, dispatch)};
};

/* const mapDispatchToProps = dispatch => ({
	handleNLF: (
		data // <<-- Here's where the action is passed down
	) => dispatch(setNLF(data))
}); */

export default connect(mapStateToProps, mapDispatchToProps)(NLF);
