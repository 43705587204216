import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {gradientCandyCarrot, withUnit} from '../../../styles/utils';

export const DividerStyled = styled.div`
	height: ${(props) => (props.fancy ? '6px' : '2px')};
	margin: ${(props) => (props.fancy ? `-3px auto 0` : `${withUnit(sizes.distance.base * 2)} auto`)};
	background-color: ${(props) => (props.fancy ? `${gradientCandyCarrot()}` : `${colors.snow}`)};
	animation-duration: 0.8s;
	animation-name: ${(props) => (props.inView ? 'slidein' : '')};

	&.inView {
		max-width: ${(props) => (props.fancy ? '60%' : '100%')};
	}

	&.notInView {
		width: 0%;
	}

	@keyframes slidein {
		from {
			width: 0%;
		}

		to {
			width: 60%;
		}
	}

	${(props) => (props.small ? `margin: ${withUnit(sizes.distance.base)} auto;` : ``)};
`;
