import React from 'react';
import PropTypes from 'prop-types';
import {useInView} from 'react-intersection-observer';
import {DividerStyled} from './styles';

const Divider = (props) => {
	const {small, fancy} = props;
	const {ref, inView} = useInView({
		/* Optional options */
		threshold: 1,
		delay: 200,
		triggerOnce: true,
	});

	return (
		<DividerStyled
			ref={ref}
			small={small}
			fancy={fancy}
			inView={inView}
			className={inView ? 'inView' : 'notInView'}
		/>
	);
};

Divider.propTypes = {
	small: PropTypes.bool,
	fancy: PropTypes.bool,
};

Divider.defaultProps = {
	small: false,
	fancy: false,
};

/** @component */
export default Divider;
