import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import DossierList from './Container';

const DossierListWrapper = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressWpNews(sort: {fields: [date], order: DESC}) {
					edges {
						node {
							id
							title
							excerpt
							wordpress_id

							acf {
								hidden
							}
							tags {
								name
							}
							date(formatString: "MMMM DD, YYYY")
							slug
							featured_media {
								localFile {
									childImageSharp {
										id
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => <DossierList data={data} {...props} />}
	/>
);

export default DossierListWrapper;
