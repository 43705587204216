import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import {CleoAppStyled, CleoAppContentStyled, CleoLogoStyled, TextStyled, ImageStyled} from './styles';
import Button from '../../Elements/Button/Button';

const CleoApp = props => {
	const {data} = props;

	const imageCleo = data.imageOne ? (
		<Img fluid={data.imageOne.childImageSharp.fluid} style={{height: 'inherit'}} />
	) : null;

	return (
		<CleoAppStyled className="ap-cleo-app">
			<ImageStyled>{imageCleo}</ImageStyled>
			<CleoAppContentStyled>
				<CleoLogoStyled className="ap-cleo-app__logo" />
				<TextStyled maxWidth={850} content="Die App, die dir hilft, deinen Alltag mit MS zu meistern." />
				<div>
					<Button type="link" to="/service/app" text="Mehr zu Cleo" theme="white" />
				</div>
			</CleoAppContentStyled>
		</CleoAppStyled>
	);
};

CleoApp.propTypes = {
	data: PropTypes.objectOf(PropTypes.object)
};

CleoApp.defaultProps = {
	data: null
};

export default CleoApp;
